<template>
    <div>
        <h1>Re-batched Items</h1>
        <form @submit.prevent="runReport">
            <Options />
            <Fields />
            <Results />
        </form>
    </div>
</template>

<script>
    import { store } from '@/store/Report.store.js';
    import Options from "./rework_opt";
    import Results from "./utils/Results";
    import Fields from "./utils/Fields";

    export default {
        name: 'rework',
        components: {
            Results,
            Options,
            Fields
        },
        data() {
            return {
                state: store.state,
                optionFields: {
                    dateFrom: {val: new Date().toLocaleDateString(), qsField: '[datetimes][rework_date_from]'},
                    dateTo: {val: new Date().toLocaleDateString(), qsField: '[datetimes][rework_date_to]'},
                    cdiLocationId: {val: 0, qsField: '[numbers][t.cdi_location_id]'},
                    description: {val: 0, qsField: '[strings][rework_description]'},
                    type: {val: 0, qsField: '[rework_type]'},
                    status: {val: 0, qsField: '[znumbers][r.disabled]'},
                    employeeId: {val: 0, qsField: '[numbers][r.user_id]'}
                },
                groupByFields: {},
                dataFields: {
                    rework_date:        {label: 'Date', checked: true},
                    tracker_id:         {label: 'Order', checked: true},
                    nomenclature:       {label: 'Item', checked: true},
                    qty:                {label: 'QTY', checked: false},
                    final_price:        {label: 'Price', checked: false},
                    rework_cost:        {label: 'Cost', checked: false},
                    rework_description: {label: 'Error Type', checked: true},
                    cause_of_error:      {label: 'Cause of Error', checked: true},
                    items_needed_for_batch: {label: 'Notes', checked: true},
                    rework_notes:       {label: 'Notes', checked: true},
                    employee:           {label: 'Employee Responsible', checked: false},
                    type:               {label: 'Department', checked: true},
                    engineer:           {label: 'Engineer', checked: false},
                    status:             {label: 'Status', checked: false}
                }
            }
        },
        methods: {
            runReport: function() {
                store.runReport();
            }
        },
        created() {
            this.$appStore.setTitle(['Re-batched Items']);
            this.$set(store.state.results, this.$options.name, '');
            store.setReport(this.$options.name, this.optionFields, this.groupByFields, this.dataFields);
        }
    }
</script>