<template>
    <div class="panel panel-default">
        <div class="panel-heading"></div>
        <div class="row panel-body">
            <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Start Date</label>
                <DatePicker v-model="state.optionFields.dateFrom.val" :input-attributes="{class: 'form-control'}"></DatePicker>
            </div>
            <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group">
                <label>End Date</label>
                <DatePicker v-model="state.optionFields.dateTo.val" :input-attributes="{class: 'form-control'}"></DatePicker>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>CDI Location</label>
                <select class="form-control" v-model="state.optionFields.cdiLocationId.val">
                    <option value="0">All</option>
                    <option v-for="[id, location] in cache.cdiLocationCache.CDI_LOCATIONS" v-bind:key="id" :value="id">{{ location }}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Error Type</label>
                <select class="form-control" v-model="state.optionFields.description.val">
                    <option value="0">All</option>
                    <option v-for="(rework_option, index) in cache.trackerCache.TRACKER_REWORK_OPTS" v-bind:key="index" :value="rework_option">{{ rework_option }}</option>
                    <option value="" disabled>-----</option>
                    <option v-for="(rework_option) in cache.trackerCache.HISTORICAL_TRACKER_REWORK_OPTS" v-bind:key="rework_option" :value="rework_option">{{ rework_option }}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Department</label>
                <select class="form-control" v-model="state.optionFields.type.val">
                    <option value="-1">All</option>
                    <option value="0">Production</option>
                    <option value="1">Engineering</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Status</label>
                <select class="form-control" v-model="state.optionFields.status.val">
                    <option value="-1">All</option>
                    <option value="0">Active</option>
                    <option value="1">Disabled</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Employee Responsible</label>
                <Typeahead cdi_type="employee" v-bind:ID.sync="state.optionFields.employeeId.val" />
            </div>
        </div>
    </div>
</template>

<script>
    import { store } from '@/store/Report.store.js';
    import cdiLocationCache from '@/cache/cdiLocation.cache.js';
    import trackerCache from '@/cache/tracker.cache.js';
    import DatePicker from '../utils/DatePicker';
    import Typeahead from "@/components/utils/typeaheads/Typeahead";

    export default {
        name: "Options",
        components: {DatePicker, Typeahead },
        data() {
            return {
                state: store.state,
                cache: {
                    cdiLocationCache,
                    trackerCache
                }
            }
        }
    }
</script>

<style scoped>

</style>